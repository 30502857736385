:root {
    /* colours */
    --colour-blue:              #13152F;
    --colour-lightblue:         #292d6f;
    --colour-darkblue:          #13152F;
    --colour-red:               #7D0000;
    --colour-white:             #FFFFFF;
    --colour-grey:              #555;
    --colour-lightgrey:         #999;
    --colour-darkgrey:          #222;
    --colour-pink:              #c68b67;

    --primary-colour:           var(--colour-blue);
    --light-colour:             var(--colour-lightblue);
    --dark-colour:              var(--colour-darkblue);

    --primary-colour-hover:     var(--colour-lightblue);
    --disabled-colour:          var(--colour-lightgrey);

    --alt-background:           #EBEBEB;
    --lighter-background:       #F5F5F5;

    --ha-icon-red:              #5d0a08;
    --ha-icon-grey:             #333;

    --tab-border:               1px solid var(--colour-darkblue);
    --shadow-border:            0px 0px 5px rgba(0, 0, 0, 0.25);

    --toast-error-colour:       rgba(120, 0, 0, 0.80);
    --toast-success-colour:     rgba(0, 43, 130, 0.90);
    --toast-semierror-colour:   rgba(27, 85, 0, 0.85);

    --scrollThumbColour:        var(--colour-pink);
    --scrollBarColour:          var(--lighter-background);
    
    /* constants */
    --ripple-effect-duration:   0.5s;
    --filter-animate-time:      0.25s;
}

.no-render {
    display: none !important;
}
.invisibox {
    visibility: hidden !important;
}

/* buttons */
button { 
    outline: none;
}

.btn { 
    font-size: 1rem;
    overflow-wrap: break-word;
    background-color: var(--primary-colour);
    color: var(--colour-white);
    padding: 0.4rem 0.7rem;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid var(--primary-colour);
    text-decoration: none;
}

.btn:hover {
    background-color: var(--light-colour);
    transition: ease-in-out 200ms;
}

.btn:focus {
    outline: none;
}

.btn:disabled {
    background-color: var(--disabled-colour);
    border-color: var(--disabled-colour);
}

.btn-warning:hover { 
    background-color: var(--colour-red);
}

.btn.btn-secondary { 
    background-color: var(--colour-white);
    color: var(--primary-colour);
    border: 1px solid var(--primary-colour);
}

.btn.btn-secondary:hover { 
    background-color: var(--primary-colour);
    color: var(--colour-white);
    transition: ease-in-out 200ms;
}

.btn.btn-small {
    font-size: 0.75rem;
    padding: 0.15rem 0.4rem;
}

.list-button.btn-warning:hover { 
    background-color: transparent;
    color: var(--colour-red);
}

.link-btn {
    font-size: 1rem;
    overflow-wrap: break-word;
    background: none;
    color: var(--primary-colour);
    padding: 0.1rem;
    border: none;
    cursor: pointer;
}

.link-btn:focus {
    outline: none;
}

.link-btn:disabled {
    color: var(--disabled-colour);
    cursor: unset;
}

.table-filter-button-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rt-th button.link-btn {
    font-size: small;
}

/* Ripple effect */
.btn.ripple-effect {
    background-position: center;
    transition: background var(--ripple-effect-duration);
}
  
.btn.ripple-effect:hover {
    background: var(--light-colour) radial-gradient(circle, transparent 1%, var(--light-colour) 1%) center/15000%;
}

.btn.ripple-effect:active {
    background-size: 100%;
    transition: background 0s;
}

.list-button {
    height: 35px; 
    width: 35px;
    background-color: transparent;
    color: var(--primary-colour);
    fill: var(--primary-colour);
    border: none;
    box-shadow: none;
    text-shadow: 2px 2px 1px rgba(0,0,0,0.14);
    transition: 0.3s;
    cursor: pointer;
}
    
.list-button i::before {
    font-size: 20px;
}

.list-button svg.custom-svg {
    fill: var(--primary-colour);
}

.list-button:disabled, .list-button.disabled {
    background-color: transparent;
    cursor: default;
    color: var(--disabled-colour);
    fill: var(--disabled-colour);
}

.list-button:disabled:hover, .list-button.disabled:hover {
    color: var(--disabled-colour);
    fill: var(--disabled-colour);
}

.list-button:hover {
    background-color: transparent;
    box-shadow: none;
    color: var(--primary-colour-hover);
    fill: var(--primary-colour-hover);
}

.list-button:focus {
    background-color: transparent !important;
}

.list-button.warning:hover { 
    color: var(--warning-colour) !important;
}

.list-button.semi-disabled:not(:hover) {
    color: var(--disabled-colour);
    fill: var(--disabled-colour);
}

.list-button.btn-small {
    width: 15px;
    height: 15px;
    margin: 2px;
    padding: 0px;
}

.list-icon {
    display: flex;
    align-items: center;
    fill: var(--primary-colour);
    color: var(--primary-colour);
    width: 2.9rem;
}

.list-icon.selected {
    fill: var(--colour-white);
    color: var(--color-white);
}

.list-icon > img {
    max-width: 24pt;
    max-height: 24pt;
}

.list-icon.table-icon {
    width: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.list-photo {
    width: 43px;
    height: 43px;
    object-fit: cover;
    object-position: center;
}

div.rt-td.list-photo-column {
    padding-top: 3px;
    padding-bottom: 1px;
}

* {
    /*scrollbar-width: thin;*/
    scrollbar-color: var(--scrollThumbColour) var(--scrollbarColour);
  }
*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
*::-webkit-scrollbar-track {
    background: var(--scrollbarColour);
}
*::-webkit-scrollbar-thumb {
    background-color: var(--scrollThumbColour) ;
    border-radius: 4px;
    border: 2px solid var(--scrollbarColour);
}

.left-panel {
    min-height: 100%;
    width: 15.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    background-color: var(--primary-colour);
    /* background-color: var(--alt-background); */
    color: var(--colour-white);
}

.left-panel .nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 1rem); /*add the left and right padding*/
    font-size: 1.2rem;
    padding: 0.25rem 0.2rem 0.25rem 0.8rem;
    cursor: pointer;
    margin: 0 0 0.8rem 0;
}

.nav-item__container {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: start;
    align-items: center;
    vertical-align: middle;
}

.left-panel .nav-item.bottom0 {
    margin-bottom: 1px;
}

.left-panel .nav-item.sub-item {
    font-size: 0.9rem;
    justify-content: space-between;
    padding-left: 1rem;
    width: calc(100% - 1.2rem);
}

.left-panel .nav-item.sub-item .sub-item-summary {
    font-size: 0.6rem;
    margin-left: 0.5rem;
    margin-bottom: 0.15rem;
    margin-right: 0.8rem;
    align-self: end;
}

.left-panel .list-icon {
    fill: var(--colour-white);
    color: var(--color-white);
}

.left-panel .nav-item.selected {
    background-color: var(--alt-background);
    color: var(--primary-colour);
}

.left-panel .list-icon.selected {
    fill: var(--primary-colour);
    color: var(--primary-colour);
}

.left-panel .dialog-logo {
    align-self: center;
    height: 3.41667rem;
    width: 10.41667rem;
    padding: 2rem 0.5rem 5rem 0.5rem;
    flex: 0 0 auto;
}

.left-panel .nav-item select {
    max-width: 10.4rem;
}

.nav-item_stone {
    flex-shrink: 1;
}

.nav-item_stone span {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 7.51rem; /*trial and error*/
}

.tab-button {
    height: 15px; 
    width: 15px;
    background-color: transparent;
    color: var(--colour-white);
    fill: var(--colour-white);
    border: none;
    box-shadow: none;
    text-shadow: 2px 2px 1px rgba(0,0,0,0.14);
    cursor: pointer;
    margin: 2px;
    padding: 0px;
}

.tab-button:focus {
    outline: none;
}

.selected .tab-button {
    color: var(--primary-colour);
    fill: var(--primary-colour);
}

.react-tabs__tab--selected .tab-button {
    color: white;
    fill: white;
    text-shadow: 2px 2px 1px rgba(255,255,255,0.14);
}

.nav-item__container .tab-button {
    vertical-align: text-top;
}

.main-panel {
    width: 92%;
    margin: 3rem 4%;
}

.main-panel__header {
    position: sticky;
    top: 0;
    left: 0; 
    height: 30px;
    width: 100%;
    background-color: var(--alt-background);
    z-index: 1;
}

.flexbox-filler {
    flex-grow: 1;
}

.small-right-bar {
    width: 0.85rem;
    height: 100%;
    background-color: var(--primary-colour);
    float: right;
    position: sticky;
    top: 0px;
    z-index: 2;
}

.profile-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: center;
    height: 100%;
}

.profile-item {
    margin: 1rem;
    color: var(--colour-white);
    flex-grow: 0;
}

a.profile-item {
    text-decoration: underline;
}

.profile-header {
    font-size: larger;
    font-weight: 400;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.profile-legend {
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-left: 0.5rem;
}
.profile-legend > img {
    width: 10rem;
}

.profile-handle {
    position: absolute;
    width: max-content;
    height: 1.5rem;
    right: 0.5rem;
    top: 0.25rem;
    background-color: var(--colour-white);
    color: var(--dark-colour);
    overflow: visible;
    border: 1px solid var(--dark-colour);
    padding: 0 0.25rem;
    cursor: pointer;
    font-weight: 400;
}
.profile-handle.profile-open {
    right: unset;
    left: -0.5rem;
    padding: 0 0.3rem 0 0.35rem;
}

body {
    position: relative;
    margin: 0;
    display: flex;
    min-height: 100vh;
    height: auto;
    flex-direction: column;
    font-weight: 300;
    justify-content: space-between;
    overflow-y: scroll;
} 

main {
    flex: 0 0 auto;
}

a {
    cursor: pointer;
    color: var(--primary-colour);
}

li.tab.headertab a {
    font-size: 12px;
}

li.tab.headertab-1 a {
    font-size: 12px;
}
  
li.collection-item { 
      font-weight: 500;
}

.no-padding { 
     padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.centred-text { 
    text-align: center;
}

footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    background-color: var(--primary-colour);
    padding: 1rem;
}

.modal-container { 
    min-width: 1280px;
    max-width: 1280px !important;
}

.modal-list { 
    min-width: 950px;
    max-width: 950px !important;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.loading-overlay {
    background-color: rgba(0,0,0,0.4);
}

.loading-modal {
    background-color: rgba(255,255,255,0.75);
    min-width: 20rem;
}

.ellipsis-after:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 1s infinite;      
    animation: ellipsis steps(4,end) 1s infinite;
    content: "\2026";
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 1.0rem;
    }
}
@-webkit-keyframes ellipsis {
    to {
        width: 1.0rem;
    }
}

.clickable {
    cursor: pointer;
}

.spinning {
    animation: spin 4s infinite linear;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.page-footer {
  padding-top: 14px;
}

/* tables */
.table-wrapper {
    position: relative;
}
.tabletop-buttons {
    position: absolute;
    right: 0;
    top: -2.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: end;
}
.table-contents-header {
    margin-right: 0.8rem;
    align-self: center;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: small;
}

.ReactTable {
    max-height: calc(100vh - 6rem - 32px);
}
.ReactTable .rt-table {
    overflow-x: visible;
}
.ReactTable .rt-tbody {
    min-width: fit-content !important;
}

.ReactTable .rt-thead:not(.-filters) .rt-tr .rt-th {
  background-color: var(--alt-background);
  color: var(--primary-colour);
  font-size: 12px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 4px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
  vertical-align: middle;
  border: none;
  text-transform: uppercase;
  outline: none;
}
.ReactTable .rt-thead.-filters .rt-tr .rt-th {
  text-align: left;
  vertical-align: bottom;
  background-color: var(--lighter-background);
  border-radius: 2px;
  border: none;
  outline: none;
  min-height: 42px;
}
.ReactTable .rt-thead.-filters select {
    height: 32px;
  }
.ReactTable .rt-thead.-filters .rt-tr .rt-th input[type="text"]:not(.browser-default) {
  box-sizing: border-box;
  height: 32px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.rt-tr {
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0,0,0,0.12);
  border-top: none;
  border-left: none;
  border-right: none;
  font-weight: 400;
}

.ReactTable .rt-tbody .rt-tr {
  align-items: center;
}

.rt-th.-sort-asc {  
    display: flex; 
    justify-content: space-between;
    box-shadow: none !important;
}

.rt-th.-sort-desc {  
    display: flex; 
    justify-content: space-between;
    box-shadow: none !important;
}

.rt-th.-sort-asc::after { 
    content: '\f0de';
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    height: 14px; 
    width: 20px;
    margin-top: 4px;
}

.rt-th.-sort-desc::after { 
    content: '\f0dd';
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    height: 14px; 
    width: 20px;
    margin-top: 4px;
}

.ReactTable .rt-thead input[type="date"] { 
    height: 32px !important;
}

.rt-noData { 
    margin-top: 60px;
}

.ReactTable [type="checkbox"]:not(:checked), .ReactTable [type="checkbox"]:checked { 
    position: relative;
    opacity: 1;     
    pointer-events: all;
}

.ReactTable .-pagination .-pageJump input {
    height: 20px;
    margin: 0px;
}

.ReactTable .rt-tbody .rt-td {
    text-align: center;
    border-right-color: rgba(0,0,0,0.15);
}

.ReactTable .rt-tbody, .ReactTable .rt-thead {
    font-size: smaller;
}

.rt-td:hover {
    overflow: visible;
}
.rt-td:hover span {
    position: relative;
    background-color: #F2F2F2;
}

.rt-td .list-cell {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.ReactTable .rt-thead:not(.-filters) .rt-tr .rt-th {
    text-align: center;
}

.filter-header {
    width: 95%;
    height: 32px;
    padding: 2px 7px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    background-color: var(--colour-white);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.filter-header::before {
    content: '\f0da';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    height: 14px; 
    width: 20px;
    margin-top: 5px;
    transform-origin: 0.125rem 0.5rem;
    transition: var(--filter-animate-time) ease-out;
}
.filter-header[showpopup]::before {
    transform: rotate(90deg);
}

.filter-popup {
    transition: var(--filter-animate-time) ease-out;
    z-index: 1;
    background-color: white;
    padding: 8px;
    border: solid 1px rgba(0,0,0,0.15)
}
.filter-popup:not([showpopup]) {
    visibility: hidden;
    opacity: 10%;
}
.filter-popup:focus {
    outline: none;
}

.react-calendar {
    width: 18rem;
}
.react-calendar button {
    border: none;
    background: none;
    border-radius: 50%;
}
.react-calendar button:not([disabled]) {
    cursor: pointer;
    transition: 0.3s ease-out;
}
.react-calendar button:focus {
    outline: none;
}
button.react-calendar__tile--active {
    background-color: var(--light-colour);
}
.react-calendar__month-view__weekdays__weekday {
    font-size: small;
    text-align: center;
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
    text-decoration: none;
}

.info-tip {
    color: var(--primary-colour);
    margin-left: 8px;
    margin-top: 0px;
    margin-right: 8px;
    margin-bottom: 0px;
}

.Toastify__toast--success {
    background-color: var(--toast-success-colour);
}
.Toastify__toast--error {
    background-color: var(--toast-error-colour);
}
.Toastify__toast--error.happier {
    background-color: var(--toast-semierror-colour);
}
span.toast-icon {
    font-size: 1.3rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
}

/* React Tabs */

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: var(--tab-border);
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
    height: 1.45rem;
    display: inline-block;
    background-color: #fff;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-weight: 600;
    border: var(--tab-border);
    border-radius: 10px 10px 0 0;
    color: var(--primary-colour);
}

.react-tabs__tab--selected {
  /*background-color: var(--primary-colour);*/
  background-image: linear-gradient(var(--primary-colour) 80%, white);
  color: white;
  /*border-bottom: none;*/
  border-bottom: 1px solid white;
}

.react-tabs__tab--disabled {
  background-color: var(--disabled-colour);
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.right {
    float: right;
}

.expand {
    width: 100%;
    height: 100%;
}

.row {
    width: 100%;
}
.row.spaced {
    margin: 0.5rem auto;
}

.space-contents > * {
    margin-right: 0.5rem;
}

.caption40 > div:nth-child(1) {
    width: 40%;
    display: inline-block;
}
.caption40 > div:nth-child(2) {
    width: 60%;
    display: inline-block;
}

.caption38 > div:nth-child(1) {
    width: 37.5%;
    display: inline-block;
}
.caption38 > div:nth-child(2) {
    width: 62.5%;
    display: inline-block;
}

.caption25 > div:nth-child(1) {
    width: 25%;
    display: inline-block;
}
.caption25 > div:nth-child(2) {
    width: 75%;
    display: inline-block;
}

.caption20 > div:nth-child(1) {
    width: 20%;
    display: inline-block;
}
.caption20 > div:nth-child(2) {
    width: 80%;
    display: inline-block;
}

.even-split {
    display: table;
    table-layout: fixed;
}
.even-split > div {
    display: table-cell;
}
.even-split > div > button {
    width: calc(100% - 1rem);
    margin: 0 0.5rem;
}

.inline-col {
    display: inline;
    margin-right: 1rem;
}

.tab-panel-container {
    height: 70vh;
    overflow-y: auto;
}

.header-rule {
    border: 6px solid var(--primary-colour);
    margin-bottom: 1rem;
}

.footer-rule {
    border: 3px solid var(--primary-colour);
    margin-top: 1rem;
}

.footer-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
    align-content: flex-start;
}

.footer-text {
    margin: 0 1rem;
}

.login-header {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
}

.login-twocol {
    width: 100%;
    margin: 0;
    display: grid;
    padding: 6rem;
    box-sizing: border-box;
}

.login-twocol.first-block {
    padding: 6rem 6rem 6rem 0
}

.markup-row {
    display: grid;
    grid-template-columns: 1.5rem 1.5rem 1rem 2rem 2rem 2rem 1rem 5rem 5rem 8rem 1rem 1.5rem;
}
.markup-row > * {
    text-align: center;
}

/* .model-req-qty {
    width: 2rem;
    margin: 0px 3px;
}
.model-req-qty.over-request {
    border: #650909 1px solid;
    border-radius: 2px;
}
.model-req-qty.over-request:focus {
    border-color: #831f1f;
    border-width: 2px;
} */
.model-req-qty {
    margin: 0px 3px;
}
.model-req-qty.over-request, .request-over-warning {
    color: var(--colour-red);
    font-weight: 500;
}

.table-stepper-button {
    color: var(--primary-colour);
    padding: 0px;
    background: none;
    border: none;
    font-size: x-small;
    cursor: pointer;
}
.table-stepper-button:disabled {
    color: var(--disabled-colour);
}
.table-stepper-button.over-request {
    color: var(--colour-red);
}

.photo-icon {
    width: 28px;
    vertical-align: middle;
}

@media (max-width: 720px) {
    /*.login-header {
        flex-direction: column;
    }*/
    .login-twocol {
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .login-twocol.first-block {
        padding: 0;
    }
    .main-login-text.login-block-beside-image {
        margin: 16px;
    }
}

.login-item {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: baseline;
    align-content: flex-start;
}

.main-login-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: 600;
    /*text-transform: uppercase;*/
    text-align: left;
    color: var(--primary-colour);
    padding: 2rem;
}

.login-block-beside-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5rem 12%;
}

.login-block-beside-image.right-col {
    margin-right: 4%;
}

.login-block-beside-image.left-col {
    margin-left: 4%;
}

.login-control {
    /*background: white;*/
    border-radius: 1rem;
    border: solid 1px var(--primary-colour);
    padding: 0.5rem 1rem;
    text-decoration: none
}

.clickable-icon {
    color: var(--primary-colour);
    cursor: pointer;
}

.btn-row {
    margin-right: 0.5rem;
}

.horizontal-slider {
    width: 20rem;
    height: 1.75rem;
    border: none;
    margin-bottom: 0.4rem;
}

.slider-track {
    top: 0.35rem;
    height: 0.15rem;
    background-color: var(--primary-colour);
}
.slider-track-0, .slider-track-2 {
    background-color: var(--colour-lightgrey);
}

.slider-handle {
    font-size: 80%;
    width: 0.85rem;
    height: 0.85rem;
    border-radius: 50%;
    border: 1px solid var(--dark-colour);
    background-color: var(--light-colour);
}

.slider-handle .handle-caption {
    text-align: center;
    position: absolute;
    top: 0.6rem;
    color: var(--dark-colour);
}

.slider-handle .caption-div {
    top: 0.9rem;
    font-size: 0.8rem;
    text-align: center;
}

.youtube-container {
    margin-top: 48px;
    width: 100%;
    text-align: center;
}

.youtube-caption {
    font-size: x-large;
    font-weight: 500;
    color: var(--primary-colour);
}

.wide-confirm {
    max-width: 80vw;
    max-height: 60vh;
    width: 75%;
    padding: 1rem;
}

.confirmation-container {
    margin: 0.6rem;
    padding: 0.4rem;
}

.confirmation-container.outer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: nowrap;
    max-height: calc(60vh - 2vh);
}

.confirmation-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: space-around;
}

.confirmation-container.border {
    /*border: 2px solid var(--alt-background);
    border-radius: 3px;*/
    box-shadow: var(--shadow-border);
}

.confirmation-container.flexible {
    flex-shrink: 1;
    flex-grow: 1;
}
.confirmation-container.flexible-shrinkonly {
    flex-shrink: 1;
}

.summaryheightlimit {
    max-height: calc(60vh - 16rem);
}

.confirmation-container.scrollable {
    overflow-y: scroll;
}
/*.confirmation-container.scrollable {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollThumbColour) var(--scrollbarColour);
  }
.confirmation-container.scrollable::-webkit-scrollbar {
    width: 8px;
}
.confirmation-container.scrollable::-webkit-scrollbar-track {
    background: var(--scrollbarColour);
}
.confirmation-container.scrollable::-webkit-scrollbar-thumb {
    background-color: var(--scrollThumbColour) ;
    border-radius: 5px;
    border: 3px solid var(--scrollbarColour);
}*/

.confirmation-heading {
    font-size: larger;
    font-weight: 450;
}

.confirmation-text {
    font-size: small;
}

.confirmation-text hr {
    border: 1px solid var(--alt-background);
}

.container-h-margin {
    margin: 0 0.6rem;
}

.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: space-between;
    align-content: start;
    margin: 0rem;
    padding: 0rem;
}

.stone-card {
    width: calc(50% - 2rem);
    overflow: hidden;
}

.stone-card .tab-button {
    color: var(--colour-grey);
}

.stone-card .tab-button:hover {
    color: var(--colour-lightgrey);
}

.align-top {
    vertical-align: top;
}
.align-bottom {
    vertical-align: bottom;
}
.valign-centre {
    vertical-align: middle;
}

.bold {
    font-weight: 500;
}

.semibold {
    font-weight: 400;
}

.modal-close-button {
    background: var(--primary-colour);
    fill: var(--colour-white);
    border-radius: 25%;
    cursor: pointer;
}

.modal-close-button:hover {
    background-color: var(--light-colour);
    transition: ease-in-out 200ms;
}

.modal-close-button > svg {
    width: 20px;
    height: 20px;
}

.card {
    box-shadow: var(--shadow-border);
    margin: 1rem;
    padding: 0.5rem;
}

.column {
    margin: 0 0.5rem;
}


.section-header {
    font-size: large;
    font-weight: 400;
}

.time-only {
    font-size: small;
}

.page-title {
    font-size: x-large;
    font-weight: 450;
}

/* Stone details */
.stone-details__container {
    width: 850px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 5rem auto;
}

.stone-details__container--header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-start;
}

.stone-details__container--request-btn button {
    width: 100% !important; 
    margin: 0 !important;
}

.stone-details__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.stone-details__price-container { 
    display: flex;
    width: min-content;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    float: right;
    margin: 1rem 1rem 0 1rem;
    flex-grow: 0.2;
}

.stone-details__price--card-header { 
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0.5rem 0;   
}

.stone-details__price--card-header span {
    font-size: 22px;
    font-weight: 500;
} 

.stone-details__price--card-header i {
    font-size: 18px;
    margin: 0 0.5rem 0 0;
}

.stone-details__price--card-values {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 0 1rem 0;
    align-items: end;
}

.stone-details__price--card-values__rap-title { 
  font-size: 0.8rem;  
}

.stone-details__price--card-values__price {
    font-size: 22px;
    color: var(--primary-colour);
    font-weight: 500;
    text-align: right;
}

.stone-details__details-container { 
    min-width: 513px;
    max-width: 100%;
} 

.stone-details__page-title {
    font-size: 2rem;
    font-weight: 450;
}

.stone-details__page-parcelName {
    font-size: .9rem;
}

.stone-details__page-title--container {
   padding-left: 16px;
   flex-grow: 0;
}

.stone-details__page-matchbutton {
    margin-bottom: 1.35rem;
    margin-left: 0.2rem;
}

.stone-details__column { 
   padding: 1rem;
}

.stone-details__section {
    margin: 1rem 0 2rem 0;
    padding-bottom: 0.5rem;
}

.video-container {
    width: 23rem;
    height: 28.2rem;
}

.video-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.video-container-old {
    width: 100%;
    padding-top: 100%;
    position: relative;
}

.video-iframe-old {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border: none;
}

.video-container-dnad {
    width: 23rem;
    height: 28.2rem;
}

.video-iframe-dnad {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: 0 0;
    border: none;
}

.modal-video-container {
    width: 650px;
    height: 730px;
}
.modal-video-container-old {
    width: 580px;
    height: 630px;
}
.modal-video-container-dnad {
    width: 650px;
    height: 570px;
}
.modal-video-iframe {
    width: 100%;
    height: 100%;
    border: none;
}
.modal-video-iframe-old {
    width: 100%;
    height: 100%;
    border: none;
}
.modal-video-iframe-dnad {
    width: 100%;
    height: 100%;
    border: none;
}
.modal-still-image {
    height: 100%;
    max-height: 630px;
    max-width: 800px;
    object-fit: contain;
    aspect-ratio: auto;
}

.jwl-details__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 0 0.5rem;
}

.jwl-details__stone {
    box-shadow: var(--shadow-border);
    padding: 0.75rem 0.5rem;
    height: max-content;
}

.jwl-details__stone .video-container, .jwl-details__stone .video-container-dnad, .jwl-details__stone .video-container-old {
    margin-top: 1rem;
}

.annotated-icon {
    font-weight: 800;
}
.cert-embed {
    max-width: 90vw;
    max-height: 90vh;
    width: 68em;
    height: 56em;
}
.cert-embed {
    height: 58em;
}
.cert-embed > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}
.cert-embed iframe {
    width: 100%;
    height: 100%;
}

/* Account history */
.account-history__header { 
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.account-history__header--title { 
    height: 100%;
}

.account-history__header--title span { 
    position: absolute;
    font-size: 22px;
    font-weight: 500;
    bottom: 0;
    left: 0;
} 

/* Replenish */
.replenish__header { 
    width: 100%;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin: .6rem 0;
}

.replenish__header--title { 
    margin: 0 0 .4rem 0;    
} 

.replenish__header--spec { 
    font-size: 14px;
    padding: .2rem;
    margin: 0 .1rem 0 0;
    font-weight: 500;
}

.inv_modal--download-bill { 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.inv_modal--download-bill__title { 
    width: 100%;
    font-size: 1.2rem;
    margin: 0 0 .3rem 0;
    display: flex;
    align-items: flex-start;
} 

.inv_modal--download-bill select { 
    margin: 0 0 .3rem 0;
    width: 12rem;
}

.inv_modal--row > div:nth-child(1) {
    width: 2rem;
    display: inline-block;
}
.inv_modal--row > div:nth-child(2) {
    display: inline-block;
}

/* reassortment */
.reassort_page--maps {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: max-content 1fr;
    grid-template-areas: 'stores valuemap' 'columnmap valuemap';
}
.reassort-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.reassort-shipment, .reassort-additions, .reassort-removals {
    margin-right: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-grow: 1;
    display: grid;
    align-items: start;
    height: min-content;
    flex: 1;
}
.reassort-shipment {
    grid-template-columns: repeat(7, auto);
}
.reassort-additions {
    grid-template-columns: repeat(8, auto);
}
.reassort-removals {
    /* set as style since number of columns is variable */
}
.reassort-ship-header, .reassort-additions-header, .reassort-removals-header {
    font-weight: 500;
    font-size: large;
    grid-column-start: 1;
}
.reassort-ship-header {
    grid-column-end: 8;
}
.reassort-additions-header {
    grid-column-end: 9;
}
.reassort-removals-header {
    /* set as style since number of columns is variable */
}
.reassort-shipment > *, .reassort-additions > *, .reassort-removals > * {
    margin: 0 0.5rem;
}

.reassort_ideal--crosstabs, .reassort_ideal--group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.reassort_ideal--group {
    width: fit-content;
}
.reassort_ideal--group > * {
    margin-right: 4px;
}
.reassort_ideal--wccgrid {
    display: grid;
    gap: 0px;
}
.reassort_ideal--upperheader, .reassort_ideal--lowerheader, .reassort_ideal--colheader, .reassort_ideal--cell {
    width: 100%;
    height: 100%;
    border-right: 1px solid;
    border-bottom: 1px solid;
    text-align: center;
    padding: 0px 3px;
    box-sizing: border-box;
}
.reassort_ideal--upperheader {
    grid-row-start: 1;
    grid-row-end: 1;
    border-top: 1px solid;
}
.reassort_ideal--lowerheader {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-end: span 1;
}
.reassort_ideal--colheader {
    grid-column-start: 1;
    grid-column-end: 1;
    text-align: right;
    border-left: 1px solid;
}
.reassort_ideal--cell {
    grid-column-end: span 1;
    grid-row-end: span 1;
}
.reassort_page--ideal_table {
    display: grid;
}
.reassort_page--ideal_flat_header {
    font-weight: 600;
    text-align: center;
    width: 100%;
}
.reassort_page--ideal_flat_cell {
    text-align: center;
    width: 100%
}

/* gallery */
.gallery-component {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.gallery-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    width: min-content;
    cursor: pointer;
    position: relative;
    background-color: var(--lighter-background);
    border-radius: 1rem;
    border: 1px solid var(--primary-colour);
    box-shadow: 1px 3px 5px var(--primary-colour);
}
.gallery-photo {
    height: 16rem;
    width: 16rem;
}
.gallery-photo > img {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
}
.gallery-photo > iframe {
    height: 100%;
    width: 100%;
}
.gallery-caption {
    display: grid;
    grid-template-columns: 1fr 2rem;
    grid-template-rows: min-content;
    width: 100%;
}
.gallery-caption > .tier {
    font-weight: 400;
    grid-area: 1 / 1 / span 1 / span 2;
}
.gallery-caption > .basic-properties {
    font-weight: 400;
    grid-area: 2 / 1 / span 1 / span 2;
}
.gallery-caption > .minor-properties {
    color: #666;
    grid-area: 3 / 1 / span 1 / span 1;
}
.gallery-caption > .cert {
    color: #666;
    grid-area: 4 / 1 / span 1 / span 1;
}
.gallery-caption > .partial-price {
    margin-top: 0.25rem;
    color: #666;
    grid-area: 5 / 1 / span 1 / span 1;
}
.gallery-caption > .total-price {
    font-weight: 500;
    font-size: large;
    margin-top: 0.5rem;
    grid-area: 6 / 1 / span 1 / span 1;
}
.gallery-caption > span {
    justify-self: start;
    align-self: end;
}
.gallery-caption > .cart-in-caption {
    grid-area: 5 / 2 / span 2 / span 1;
    margin-bottom: -3px;
}
.gallery-caption > .cert-in-caption {
    grid-area: 4 / 2 / span 2 / span 1;
    margin-bottom: -8px;
}
.gallery-caption > .cart-in-caption-2 {
    grid-area: 4 / 2 / span 2 / span 1;
    margin-bottom: -3px;
}
.gallery-caption > .cert-in-caption-2 {
    grid-area: 2 / 2 / span 2 / span 1;
    margin-bottom: -8px;
}
.gallery-caption > .list-button {
    justify-self: end;
    align-self: end;
}
.gallery-card-button {
    position: absolute;
    top: 0rem;
    right: 0rem;
    border-radius: 0.5rem;
    border: var(--primary-colour) 1px solid;
}

/* matching pair section */
.similar--main {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    align-content: center;
    max-height: 75vh;
}
.similar--left {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    overflow-y: auto;
    overflow-x: clip;
    position: relative;
    padding: 0 0.75rem;
}
.similar--left hr {
    width: 100%;
}
.similar--left div {
    text-align: center;
    cursor: pointer;
}
.similar--selected_container {
    position: relative;
}
.similar--selected {
    font-weight: 400;
    /*background-color: var(--primary-colour);
    color: var(--alt-background);*/
}
.similar--selected_bullet {
    position: absolute;
    left: -0.5rem;
    top: 0;
}
.similar--pair {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: auto;
    max-height: 75vh;
}
.similar--pair > div {
    margin: 0.75rem 1.5rem 0px 1.5rem;
}
.similar--stone_head {
    font-weight: 500;
}
.similar--stone_group {
    max-width: max-content;
    width: 15rem;
    height: min-content;
    display: grid;
    grid-template-columns: auto auto;
}
.gridspan {
    grid-column: 1 / -1;
}
.similar--stone_group > .semibold {
    margin-left: 0.75rem;
}
.similar--detail_head {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
}

/* awkward custom override */
.list-icon .fa-shopping-cart {
    font-size: larger;
}

.capitalize {
    text-transform: capitalize;
}

.input-block {
    display: inline-block;
    width: min-content;
}

.login-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.login-title {
    font-size: 2.7rem;
    font-weight: 500;
}

.login-text {
    font-size: 1.5rem;
    font-weight: 400;
}

.login-login-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-grow: 1;
    background-color: var(--alt-background);
}
.login-login-body label {
    /*text-transform: uppercase;*/
    font-size: large;
    font-weight: 500;
}

.referral {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    flex-grow: 1;
    background-color: var(--alt-background);
}
.referral > img {
    height: 20rem;
}

.referral-panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.referral-text-block {
    width: 50%;
    min-width: 30rem;
    max-width: 40rem;
}
.referral-text-block > span {
    display: inline-block;
    margin-bottom: 1rem;
}
.referral-panel > * {
    margin-bottom: 1rem;
}
.referral-panel label {
    text-transform: uppercase;
    font-size: large;
    font-weight: 500;
}

.static-page-frame {
    flex-grow: 1;
    border: none;
}

.login-header .btn.login-control {
    font-size: large;
    font-weight: 500;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.btn.login-control.selected {
    background-color: var(--colour-lightblue);
    color: var(--colour-white);
}
.login-header .login-control.btn-secondary {
    border: none;
}

/* begin mck section */
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face {
	font-family: "DINPro";
	font-weight: 400;
	font-display: swap;
	src: url(/files/DINPro-Regular.woff2) format("woff2"),
    url(/files/DINPro-Regular.woff) format("woff"),
    url(/files/DINPro-Regular.ttf) format("truetype");
}
@font-face {
	font-family: "DINPro";
	font-weight: 500;
	font-display: swap;
	src: url(/files/DINPro-Medium.woff2) format("woff2"),
    url(/files/DINPro-Medium.woff) format("woff"),
    url(/files/DINPro-Medium.ttf) format("truetype");
}
@font-face {
	font-family: "DINPro";
	font-weight: 700;
	font-display: swap;
	src: url(/files/DINPro-Bold.woff2) format("woff2"),
    url(/files/DINPro-Bold.woff) format("woff"),
    url(/files/DINPro-Bold.ttf) format("truetype");
}
@font-face {
	font-family: "Avenir";
	font-weight: 700;
	font-display: swap;
	src: url(/files/Avenir-Heavy.woff2) format("woff2"),
    url(/files/Avenir-Heavy.woff) format("woff"),
    url(/files/Avenir-Heavy.ttf) format("truetype");
}

.login-form-wrapper {
	display: flex;
	align-items: center !important;
    justify-content: center !important;
    flex-direction: row !important;
    background-color: #13152f;
    padding: 0 20px !important;
}

.login-form-wrapper .login-login-body {
	max-width: 320px;
	height: auto !important;
	max-height: auto;
	background-color: transparent !important;
	width: 100%;
}

.login-form-wrapper .login-login-body > div {
	width: 100% !important;
}

.login-form-wrapper .login-login-body .login-control {
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid rgba(255,255,255,0.2) !important;
	background-color: transparent;
	padding: 0;
	width: 100%;
	height: 40px;
	margin-bottom: 20px;
	font-size: 16px;
	color: #fff;
	transition: all .2s ease-in-out;
	font-family: 'DINPro';
}

.login-form-wrapper .login-login-body .login-control:focus {
	outline: none;
	border-bottom: 1px solid rgba(255,255,255,1) !important;
}

.login-form-wrapper .login-login-body .btn,
.login-form-wrapper .login-login-body .btn:active,
.login-form-wrapper .login-login-body .btn:focus {
	background-color: rgba(255,255,255,0);
	width: 100%;
	padding: 0 !important;
	border-radius: 40px;
	border:2px solid rgba(255,255,255,0.2) !important;
	margin-top: 20px;
	font-size: 14px;
	font-weight: 600;

}

.login-form-wrapper .login-login-body .btn:hover {
	background-color: #DCBFA6;
	color: #13152f;
	border-color: #DCBFA6;
}

.referral-form {
  background-color: #13152f !important;
  color: #fff;
}

.referral-form .referral-text-block {
    max-width: 100% !important;
    width: 100% !important;
}

.referral-form .referral-text-block .referral-panel {
  display: flex;
  flex-wrap: wrap;
}


.referral-form .referral-text-block .referral-panel .input-block {
  width: calc(50% - 10px);
  padding: 0 5px;
}

.referral-form .referral-text-block .input-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.referral-form .referral-text-block .input-block input {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  background-color: transparent;
  padding: 0;
  height: 40px;
  font-size: 16px;
  color: rgba(255,255,255,1);
  transition: all .2s ease-in-out;
  font-family: 'DINPro';
}

.referral-form .referral-text-block .input-block input::placeholder {
  color: rgba(255,255,255,0.2);
}

.referral-form .referral-text-block .input-block input:focus {
  outline: none;
  border-bottom: 1px solid rgba(255,255,255,1);;
}

.referral-form .referral-text-block .input-block label {
  font-size: 0;
}

.referral-form .referral-text-block button {
  margin-left: 5px;
  width: calc(50% - 10px) !important;
  font-weight: 600;
  font-size: 14px;
  border:1px solid rgba(255,255,255,1);
  border-radius: 40px;
  height: 40px;
  margin-top: 10px;
  font-family: 'DINPro';
}

.referral-form .referral-text-block button:hover {
  color: #13152f;
  background-color: #DCBFA6;
  border-color: #DCBFA6;
}

@media screen and (max-width: 720px) {
    .referral-form .referral-panel {
        flex-direction: row !important;
      }
}

@media screen and (max-width: 390px) {
    .referral-form .referral-text-block {
        width: 100% !important;
    }
    .referral-form .referral-text-block .referral-panel .input-block {
        width: 100%;
        padding: 0;
    }
  
    .referral-form .referral-text-block button {
        width: 100% !important;
        margin: 0;
    }
}

body:has(.referral-form), body:has(.login-form-wrapper) {
    overflow-y: auto;
}
/* end mck */

.mobile-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: x-large;
    min-width: 60vw;
    min-height: 70vh;
}
.mobile-menu a {
    margin: 1.5rem 0;
    font-size: large;
    font-weight: 600;
}

.login-footer {
    width: 100%;
    margin: 0;
    padding: 0;
    background: var(--primary-colour);
    color: white;
    font-size: initial;
    font-weight: 400;
    position: relative;
}
.login-footer-logo {
    text-align: left;
}
.login-footer-logo img {
    height: 5rem;
    padding: 2rem 4rem 0 4rem;
}
.login-footer-connect {
    text-align: left;
    margin-bottom: 2rem;
    display: inline-block;
}
.login-footer-link {
    color: var(--color-white);
}
.login-footer-copyright {
    text-align: right;
    position: absolute;
    right: 2rem;
    bottom: 2rem;
}

@media (max-width: 720px) {
    .desktop-only {
        display: none !important;
    }

    .login-footer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-end;
        align-content: space-between;
        height: 9rem;
    }
    .login-footer-logo img {
        padding-left: 1rem;
        padding-bottom: 1rem;
    }
    .login-footer-connect {
        display: unset;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
    }
    .login-footer-copyright {
        position: unset;
        right: unset;
        bottom: unset;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
    }
    .login-footer-about {
        text-align: right;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
    }
    
    .referral {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;
        flex-grow: unset;
        background-color: var(--alt-background);
    }
    .referral > img {
        height: 12rem;
    }
    .referral-panel {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;
    }
    .referral-text-block {
        min-width: unset;
        max-width: 100%;
        width: 80%;
    }
}

@media (min-width: 721px) {
    .mobile-only {
        display: none !important;
    }
}

.embedded-html-iframe {
    width: 100%;
    height: 90vh;
    border: none;
}